



















import { Component, Vue } from 'vue-property-decorator';
import OrganisationProfileView from '@/modules/organisation/pages/OrganisationProfileView.vue';

@Component({
  name: 'account',

  components: {
    OrganisationProfileView,
  },
  layout: 'AppMain',
})
export default class Account extends Vue {}
